export var ColumnType;
(function (ColumnType) {
    ColumnType["Any"] = "any";
    ColumnType["Numeric"] = "numeric";
    ColumnType["Text"] = "text";
    ColumnType["Datetime"] = "datetime";
})(ColumnType || (ColumnType = {}));
export var ExportColumns;
(function (ExportColumns) {
    ExportColumns["All"] = "all";
    ExportColumns["Visible"] = "visible";
})(ExportColumns || (ExportColumns = {}));
export var ExportFormat;
(function (ExportFormat) {
    ExportFormat["Csv"] = "csv";
    ExportFormat["Xlsx"] = "xlsx";
    ExportFormat["None"] = "none";
})(ExportFormat || (ExportFormat = {}));
export var ExportHeaders;
(function (ExportHeaders) {
    ExportHeaders["Ids"] = "ids";
    ExportHeaders["Names"] = "names";
    ExportHeaders["None"] = "none";
    ExportHeaders["Display"] = "display";
})(ExportHeaders || (ExportHeaders = {}));
export var SortMode;
(function (SortMode) {
    SortMode["Single"] = "single";
    SortMode["Multi"] = "multi";
})(SortMode || (SortMode = {}));
export var TableAction;
(function (TableAction) {
    TableAction["Custom"] = "custom";
    TableAction["Native"] = "native";
    TableAction["None"] = "none";
})(TableAction || (TableAction = {}));
export var ChangeAction;
(function (ChangeAction) {
    ChangeAction["Coerce"] = "coerce";
    ChangeAction["None"] = "none";
    ChangeAction["Validate"] = "validate";
})(ChangeAction || (ChangeAction = {}));
export var ChangeFailure;
(function (ChangeFailure) {
    ChangeFailure["Default"] = "default";
    ChangeFailure["Accept"] = "accept";
    ChangeFailure["Reject"] = "reject";
})(ChangeFailure || (ChangeFailure = {}));
export var Presentation;
(function (Presentation) {
    Presentation["Dropdown"] = "dropdown";
    Presentation["Input"] = "input";
    Presentation["Markdown"] = "markdown";
})(Presentation || (Presentation = {}));
